import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import Header from '../component/Header'
import { Button, Grid } from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { comma } from '../util'
import { toast } from 'react-hot-toast'

const App = () => {
  const [count, setCount] = useState(0)
  const [price, setPrice] = useState(100000)
  const [income, setIncome] = useState(0)
  const [normal, setNormal] = useState(3500)
  const [rare, setRare] = useState(80)
  const [normalPercent, setNormalPercent] = useState(
    (normal / (normal + rare)) * 100
  )
  const [rarePercent, setRarePercent] = useState((rare / (normal + rare)) * 100)
  const [history, setHistory] = useState([])

  const resetSetting = () => {
    setCount(0)
    setPrice(100000)
    setIncome(0)
    setNormal(3500)
    setRare(80)
  }

  const buyRandomBox = () => {
    setIncome(income + price)
    setCount(count + 1)

    const random = Number((Math.random() * (100 - 1) + 1).toFixed(2))

    console.log('random => ', random)

    if (rarePercent < random) {
      toast(`${count} 회차 : 노멀 당첨`, {
        icon: '🐶'
      })
      changeNormal(normal - 1)
      setHistory([`${count} 회차 : 노멀 당첨`, ...history])
    } else {
      toast(`${count} 회차 : 레어 당첨`, {
        icon: '⭐️'
      })
      changeRare(rare - 1)
      setHistory([`${count} 회차 : 레어 당첨`, ...history])
    }
  }

  const changeNormal = (d) => {
    setNormal(Number(d))
    updatePercentN(Number(d))
  }

  const changeRare = (d) => {
    setRare(Number(d))
    updatePercentR(Number(d))
  }

  const updatePercentN = (d) => {
    setNormalPercent((d / (d + rare)) * 100)
    setRarePercent((rare / (d + rare)) * 100)
  }

  const updatePercentR = (d) => {
    setNormalPercent((normal / (normal + d)) * 100)
    setRarePercent((d / (normal + d)) * 100)
  }

  return (
    <>
      <Header title="랜덤박스" />
      <Container maxWidth="sm">
        <Box sx={{ mt: 3 }}>
          <Typography>시뮬레이션 설정</Typography>
          <Grid container spacing={2} sx={{ mt: 0.1 }}>
            <Grid item xs={4}>
              <TextField
                type="number"
                label="랜덤박스 가격"
                variant="outlined"
                value={price}
                size="small"
                fullWidth
                onChange={(e) => {
                  setIncome(Number(e.target.value))
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="노멀"
                variant="outlined"
                value={normal}
                size="small"
                fullWidth
                onChange={(e) => changeNormal(e.target.value)}
                helperText={`${normalPercent.toFixed(2)}%`}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="레어"
                variant="outlined"
                value={rare}
                size="small"
                fullWidth
                onChange={(e) => changeRare(e.target.value)}
                helperText={`${rarePercent.toFixed(2)}%`}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography>구매회수</Typography>
              <Typography>{comma(count)} 회</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>회사 수익</Typography>
              <Typography>{comma(income)} 원</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Box style={{ maxHeight: 250, overflow: 'auto' }}>
            {history.map((item, idx) => {
              if (item.includes('노멀 당첨')) {
                return <Typography key={idx}>{item}</Typography>
              } else {
                return (
                  <Typography key={idx} style={{ color: '#ff7700' }}>
                    {item}
                  </Typography>
                )
              }
            })}
          </Box>
        </Box>
      </Container>

      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 100, right: 16 }}
        icon={<RestartAltIcon />}
        onClick={resetSetting}
      ></SpeedDial>

      <Button
        variant="contained"
        fullWidth
        style={{
          position: 'fixed',
          bottom: '1px',
          paddingTop: '10px',
          paddingBottom: '10px'
        }}
        onClick={buyRandomBox}
      >
        랜덤박스 구매 (수동)
      </Button>
    </>
  )
}

export default App
