import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import Header from '../component/Header'
import { Button, Grid, Paper } from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { comma } from '../util'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import toast from 'react-hot-toast'

const Gem = () => {
  //　구매회수
  const [count, setCount] = useState(0)

  //　회사수익
  const [income, setIncome] = useState(0)

  //　시뮬레이션 설정
  const [setting, setSetting] = useState([
    {
      text: 'lv1 -> lv2',
      success: 90,
      break: 0,
      price: 1000
    },
    {
      text: 'lv2 -> lv3',
      success: 80,
      break: 10,
      price: 2000
    },

    {
      text: 'lv3 -> lv4',
      success: 70,
      break: 20,
      price: 3000
    },
    {
      text: 'lv4 -> lv5',
      success: 60,
      break: 30,
      price: 4000
    },
    {
      text: 'lv5 -> lv6',
      success: 50,
      break: 40,
      price: 5000
    },
    {
      text: 'lv6 -> lv7',
      success: 40,
      break: 50,
      price: 6000
    },
    {
      text: 'lv7 -> lv8',
      success: 30,
      break: 60,
      price: 7000
    },
    {
      text: 'lv8 -> lv9',
      success: 20,
      break: 70,
      price: 8000
    },
    {
      text: 'lv9 -> lv10',
      success: 10,
      break: 80,
      price: 9000
    }
  ])

  //　선택된 젬
  const [selectedGem, setSelectedGem] = useState(0)

  // 젬 레벨
  const [gemLevel, setGemLevel] = useState([1, 1, 1, 1, 1])

  // 초기화
  const resetSetting = () => {
    window.location.reload()
  }

  // 젬 강화
  const enforceGem = () => {
    // 선택된 젬 레벨
    const selectedGemLevel = gemLevel[selectedGem]

    // 유효성
    if (selectedGemLevel === 10) {
      toast(`강화 최대치입니다.`, {
        icon: '✨'
      })
      return false
    }

    // 구매 회수 증가
    setCount(count + 1)

    // 회사 수익 증가
    const current = setting[selectedGemLevel - 1]
    setIncome(income + current.price)

    // 랜덤
    const random = Number((Math.random() * (100 - 1) + 1).toFixed(2))
    console.log('random #1 => ', random)

    if (current.success > random) {
      // 강화 성공의 경우
      const copy = [...gemLevel]
      copy[selectedGem] = selectedGemLevel + 1
      setGemLevel(copy)
      toast(`강화 성공 ${selectedGemLevel} -> ${selectedGemLevel + 1}`, {
        icon: '❤️‍🔥'
      })
    } else {
      // 랜덤
      const random = Number((Math.random() * (100 - 1) + 1).toFixed(2))
      console.log('random #2 => ', random)

      if (current.break > random) {
        // 젬 파괴의 경우
        const copy = [...gemLevel]
        copy[selectedGem] = 1
        setGemLevel(copy)
        toast('젬이 파괴되었습니다.', {
          icon: '💔'
        })
      } else {
        // 강화 실패의 경우
        toast('강화 실패', {
          icon: '❤️‍🩹'
        })
      }
    }
    console.log('gemLevel => ', gemLevel)
  }

  return (
    <>
      <Header title="젬 강화" />
      <Container maxWidth="sm">
        <Box sx={{ mt: 3 }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>시뮬레이션 설정</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {setting.map((item, idx) => {
                return (
                  <Grid container spacing={1} sx={{ mt: 0.1 }}>
                    <Grid item xs={3}>
                      <Typography>{item.text}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="성공확률 (%)"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={item.success}
                        onChange={(e) => {
                          const newSetting = [...setting]
                          newSetting[idx].success = Number(e.target.value)
                          setSetting(newSetting)
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="파괴확률 (%)"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={item.break}
                        onChange={(e) => {
                          const newSetting = [...setting]
                          newSetting[idx].break = Number(e.target.value)
                          setSetting(newSetting)
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="강화가격 (원)"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={item.price}
                        onChange={(e) => {
                          const newSetting = [...setting]
                          newSetting[idx].price = Number(e.target.value)
                          setSetting(newSetting)
                        }}
                      />
                    </Grid>
                  </Grid>
                )
              })}
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box>
          <div style={{ textAlign: 'center' }}>
            <img
              src="/nft.png"
              alt="nft"
              style={{ width: '200px', height: '200px' }}
            />
          </div>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Grid container justifyContent={'center'} spacing={1}>
            <Grid item xs={2}>
              <div style={{ textAlign: 'center' }}>
                <img
                  src={`/images/gem/p${gemLevel[0]}.png`}
                  alt="힘"
                  style={{ width: '50px', height: '50px' }}
                />
              </div>
              <Button
                variant={selectedGem === 0 ? 'outlined' : 'text'}
                onClick={() => {
                  setSelectedGem(0)
                }}
              >
                힘
                <br />
                lv {gemLevel[0]}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <div style={{ textAlign: 'center' }}>
                <img
                  src={`/images/gem/s${gemLevel[1]}.png`}
                  alt="속도"
                  style={{ width: '50px', height: '50px' }}
                />
              </div>
              <Button
                variant={selectedGem === 1 ? 'outlined' : 'text'}
                onClick={() => {
                  setSelectedGem(1)
                }}
                color="secondary"
              >
                속도
                <br /> lv {gemLevel[1]}
              </Button>
            </Grid>
            <Grid item xs={2.5}>
              <div style={{ textAlign: 'center' }}>
                <img
                  src={`/images/gem/a${gemLevel[2]}.png`}
                  alt="활동성"
                  style={{ width: '50px', height: '50px' }}
                />
              </div>
              <Button
                variant={selectedGem === 2 ? 'outlined' : 'text'}
                onClick={() => {
                  setSelectedGem(2)
                }}
                color="success"
              >
                활동성 <br />
                lv {gemLevel[2]}
              </Button>
            </Grid>
            <Grid item xs={2.5}>
              <div style={{ textAlign: 'center' }}>
                <img
                  src={`/images/gem/f${gemLevel[3]}.png`}
                  alt="친밀도"
                  style={{ width: '50px', height: '50px' }}
                />
              </div>
              <Button
                variant={selectedGem === 3 ? 'outlined' : 'text'}
                onClick={() => {
                  setSelectedGem(3)
                }}
                color="error"
              >
                친밀도 <br />
                lv {gemLevel[3]}
              </Button>
            </Grid>
            <Grid item xs={2.5}>
              <div style={{ textAlign: 'center' }}>
                <img
                  src={`/images/gem/l${gemLevel[4]}.png`}
                  alt="충성도"
                  style={{ width: '50px', height: '50px' }}
                />
              </div>
              <Button
                color="warning"
                variant={selectedGem === 4 ? 'outlined' : 'text'}
                onClick={() => {
                  setSelectedGem(4)
                }}
              >
                충성도 <br /> lv {gemLevel[4]}
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 1 }}>
          <Typography variant="subtitle2" textAlign={'right'} color="#4c4c4c">
            버튼을 클릭하여 강화하고 싶은 젬을 선택해주십시오.
          </Typography>
        </Box>

        <Box sx={{ mt: 5 }}>
          <Grid container>
            <Grid item xs={6} textAlign="center">
              <Typography variant="body2">구매회수</Typography>
              <Typography variant="h6">{comma(count)} 회</Typography>
            </Grid>
            <Grid item xs={6} textAlign="center">
              <Typography variant="body2">회사 수익</Typography>
              <Typography variant="h6">{comma(income)} 원</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 100, right: 16 }}
        icon={<RestartAltIcon />}
        onClick={resetSetting}
      ></SpeedDial>

      <Button
        variant="contained"
        fullWidth
        style={{
          position: 'fixed',
          bottom: '1px',
          paddingTop: '10px',
          paddingBottom: '10px'
        }}
        onClick={enforceGem}
      >
        젬 강화 (수동)
      </Button>
    </>
  )
}

export default Gem
